import { useState, lazy, Suspense } from 'react';
import './App.css';
import StartPage from './Components/StartPage/StartPage';
import MainPage from './Components/MainPage/MainPage';
import trainersList from './Info/trainers.json';
import news from './Info/news.json';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Loader from './Components/Loader/Loader';

const About = lazy(() => import('./Components/About/About'));
const TrainersPage = lazy(() => import('./Components/TrainersPage/TrainersPage'));
const SchedulePage = lazy(() => import('./Components/SchedulePage/SchedulePage'));
const NewsPage = lazy(() => import('./Components/NewsPage/NewsPage'));
const RegistrationPage = lazy(() => import('./Components/RegistrationPage/RegistrationPage'));
const PricePage = lazy(() => import('./Components/PricePage/PricePage'));

function App() {
  const [startPageOn, setStartPageOn] = useState(true);

  const closeStartPage = () => {
    setStartPageOn(false);
  };

  return (
    <div className="App">
      {startPageOn ? (
        <StartPage closePage={closeStartPage} />
      ) : (
        <Router>
          <Navbar />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<MainPage trainers={trainersList} />} />
              <Route path="/price" element={<PricePage /> } />
              <Route path="/about" element={<About />} />
              <Route path="/trainers" element={<TrainersPage trainers={trainersList}/>} />
              <Route path="/schedule" element={<SchedulePage />} />
              <Route path="/news" element={<NewsPage news={news} />} />
              <Route path="/registration" element={<RegistrationPage />} />
            </Routes>
          </Suspense>
          <Footer />
        </Router>
      )}

    </div>
  );
}

export default App;
