import './OurServices.scss';

const OurServices = () => {
  return (
    <div className="ourServices">
      <h1 className="ourServices-title">
        З нашою студією Lavanda ви отримаєте
      </h1>
      <div className="ourServices-introduce">
        <h1>Заняття з повітряної гімнастики</h1>
        <p>Початківці можуть ознайомитися з основами повітряної гімнастики на шовках. Наші інструктори забезпечують безпечне та веселе навчальне середовище.</p>
      </div>
      <div className="ourServices-advanced">
        <h1>Класи з поглибленими техніками</h1>
        <p>Досвідчені учні можуть оволодіти поглибленими навичками повітряної гімнастики. Ми зосереджуємося на складних рухах та техніках.</p>
      </div>
      <div className="ourServices-training">
        <h1>Тренування на гнучкість та силу</h1>
        <p>Покращуйте свою практику за допомогою спеціальних занять для підвищення гнучкості та сили. Підходить для всіх рівнів підготовки.</p>
      </div>
      <div className="ourServices-kids">
        <h1>Заняття для дітей різного віку</h1>
        <p>В нашій студії завжди є місця в групи дітей різного віку. Наші тренери слідкують за прогресом учнів та створюють позитивний настрій на заняттях.</p>
      </div>
      <div className="ourServices-competition">
        <h1>Участь в професійних змаганнях</h1>
        <p>Участь в змаганнях та творчих конкурсах - це зажди додаткова мотивація для учнів в нашій студії. Що допомогає нашим учням вдосконалюватися та набиратися досвіду.</p>
      </div>
      <div className="ourServices-concert">
        <h1>Постановка творчих номерів</h1>
        <p>В нашій студії є можливість замовити будь-який творчий номер для будь-якої події: конкурс, концерт, шкільний виступ тощо.</p>
      </div>
    </div>
  )
}

export default OurServices;