import './TrainerCard.scss';
import { Trainer } from '../../Utils/Types';
import { useState } from 'react';

type Props = {
  trainer: Trainer
}

const TrainerCard: React.FC<Props> = ({ trainer }) => {
  // const [openInfo, setOpenInfo] = useState(false);
  const [active, setActive] = useState(false);
  const { name, imgSrc, slogan, info } = trainer;

  return (
    <div className="trainerCard">
      <h1 className="trainerCard-title">{name}</h1>
      <p className="trainerCard-slogan">"{slogan}"</p>
      <img className="trainerCard-img" src={imgSrc} alt="trainerImg" />
      <div className={`trainerCard-info ${active ? `trainerCard-info-active` : ``}`}>
        <h3>{name}</h3>
        <h4>Стаж роботи:</h4>
        <p>{info.years} р.</p>
        <h4>Спеціалізована освіта:</h4>
        <p>{info.study}</p>
        <h4>Напрямок:</h4>
        <p>{info.accents}</p>
      </div>
      <button className="trainerCard-btn" onClick={() => setActive(!active)}>
        {!active ? `Детальніше` : `Закрити`}
      </button>
    </div>
  );
}

export default TrainerCard;