import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import "./ScrollToTop.scss";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <div
      className='scrollDiv'
      ref={topRef}
    >
    </div>)
};

export default ScrollToTop;
