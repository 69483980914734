import React, { useState } from 'react';
import './StartPage.scss';

type Props = {
  closePage: () => void;
}

const StartPage: React.FC<Props> = ({ closePage }) => {
  const [isClosing, setIsClosing]  = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closePage();
    }, 500);
  }

  return (
    <div className={`start-page ${isClosing ? 'start-page--closing' : ''}`}>
      <div className="start-page--background" />
      <div className="start-page--container">
        <img className="start-page--container-logo" src="logo.PNG" alt="logo" />
        <h1 className="start-page--container-title">Відкрийте свою внутрішню грацію</h1>
        <h3 className="start-page--container-slogan">
          Приєднуйтеся до Лаванди в місті Бровари і навчіться мистецтву разом з досвідченими тренерами. Віднайдіть силу, гнучкість та грацію в кожному русі.
        </h3>
        <button
          className="start-page--container-btnOff"
          onClick={handleClose}>
          Дізнатися більше
        </button>
      </div>
    </div>
  )
};

export default StartPage;
