import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <nav className="footer-navbar">
        <ul className="footer-navbar-list">
        <Link className="footer-navbar-list-item" to="/">Головна</Link>
          <Link className="footer-navbar-list-item" to="/about">Про студію</Link>
          <Link className="footer-navbar-list-item" to="/trainers">Наші тренери</Link>
          <Link className="footer-navbar-list-item" to="/schedule">Розклад занять</Link>
          <Link className="footer-navbar-list-item" to="/news">Наші новини</Link>
        </ul>
      </nav>

      <div className="footer-contact">
        <div className="footer-contact__adress">
          <h3>Наша адреса:</h3>
          <a href="https://maps.app.goo.gl/AmEnf7zr4LycaX8m7">
            м. Бровари, вул. Соборна 21
          </a>
        </div>

        <div className="footer-contact__phone">
          <h3>Телефон:</h3>
          <a href="tel.+380686471557">+38 (068) 647-15-57</a>
        </div>

        <div className="footer-contact__social">
          <h3>Соціальні мережі:</h3>
          <div className="footer-contact__social-icons">
            <a className="footer-contact__social-icons_instagram"
              href="https://www.instagram.com/lavanda_studio_2023/">
            </a>
            <a className="footer-contact__social-icons_telegram"
              href="https://t.me/+kQrH4E0iRMY0ZmJi">
            </a>
            <a className="footer-contact__social-icons_viber"
              href="https://www.instagram.com/">
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
