import './Intro.scss';

const Intro = () => {
  return (
    <div className="intro">
      <h1 className="intro-title">Підвищуйте свою фізичну форму в студії повітряної гімнастики Lavanda</h1>
      <h2 className="intro-text">Приєднуйтесь до нас у студії Lavanda в  місті Бровари. Наші досвідчені інструктори навчать вас технікам повітряної гімнастики на шовках, допомагаючи зміцнити силу та покращити гнучкість. Відкрийте для себе радість повітряних мистецтв разом з нами.</h2>
      <div className="intro-img"></div>
    </div>
  )
}

export default Intro;