import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Menu from "../Menu/Menu";
import './Navbar.scss';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  return (
    <nav className="navbar">
      <Link to="/">
        <img className="navbar-img" src="logo.PNG" alt="logo" />
      </Link>
      <ul className="navbar-list">
        <Link className="navbar-list-item" to="/about">Про студію</Link>
        <Link className="navbar-list-item" to="/price">Ціни</Link>
        <Link className="navbar-list-item" to="/schedule">Розклад занять</Link>
        <Link className="navbar-list-item" to="/trainers">Наші тренери</Link>
        <Link className="navbar-list-item" to="/news">Наші новини</Link>
        <Link className="navbar-list-item" to="/registration">Запис на заняття</Link>
      </ul>
      <button 
        className="navbar-menu-btn" 
        onClick={toggleMenu}
      >
      </button>

      <div ref={menuRef} className={`menu ${isOpen ? 'open' : ''}`}>
          <CSSTransition
            in={isOpen}
            timeout={500}
            unmountOnExit
            nodeRef={menuRef}
          >
            <Menu toggleMenu={toggleMenu} isOpen={isOpen} setIsOpen={setIsOpen} />
          </CSSTransition>
        </div>
    </nav>
  )
}

export default Navbar;
