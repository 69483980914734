import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "./Menu.scss";

type Props = {
  isOpen: boolean,
  toggleMenu: (event: React.MouseEvent) => void,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export const Menu: React.FC<Props> = ({ isOpen, toggleMenu, setIsOpen }) => {
  const navigate = useNavigate();

  const handleLinkClick = (event: React.MouseEvent, path: string) => {
    event.preventDefault();
    setIsOpen(!isOpen);
    navigate(path);
  };

  return (
    <div className="menu-content">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            className="menu-nav-link"
            aria-current="page"
            to="/"
            onClick={(e) => handleLinkClick(e, "/")}
          >
            Головна
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="menu-nav-link"
            to="/about"
            onClick={(e) => handleLinkClick(e, "/about")}
          >
            Про нас
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="menu-nav-link"
            to="/price"
            onClick={(e) => handleLinkClick(e, "/price")}
          >
            Ціни
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="menu-nav-link"
            to="/trainers"
            onClick={(e) => handleLinkClick(e, "/trainers")}
          >
            Наші тренери
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="menu-nav-link"
            to="/contactus"
            onClick={(e) => handleLinkClick(e, "/schedule")}
          >
            Розклад занять
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="menu-nav-link"
            to="/news"
            onClick={(e) => handleLinkClick(e, "/news")}
          >
            Наші новини
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="menu-nav-link"
            to="/registration"
            onClick={(e) => handleLinkClick(e, "/registration")}
          >
            Запис на занятя
          </Link>
        </li>
      </ul>
      <button className="menu-button" onClick={toggleMenu}></button>
    </div>
  )
}

export default Menu;